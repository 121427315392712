<template>
  <div class="home">
    <div class="home__hero">
      <div class="home__hero-inner">
        <div class="home__hero-left">
          <h1 class="home__hero-heading">Secrets.<br />Hidden in<br />emoji</h1>
          <div class="home__hero-app-store-cta">
            <span>Free</span> on iPhone and Android
          </div>
          <div class="home__hero-app-store-links">
            <a
              class="home__hero-app-store-link"
              href="https://apps.apple.com/us/app/shtum/id1567801371"
            >
              <img
                class="home__hero-app-store-badge"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1600093794/StickerCode/apple-app-store-badge.png"
                width="499"
                height="166"
                alt="Apple AppStore badge"
              />
            </a>
            <a class="home__hero-app-store-link" href="" @click="comingSoon">
              <img
                class="home__hero-app-store-badge"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1600093846/StickerCode/google-play-store-badge.png"
                width="562"
                height="166"
                alt="Google Play badge"
              />
            </a>
          </div>
        </div>
        <div class="home__hero-image-wrapper">
          <img
            ref="heroImage"
            src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621357714/Shtum/iPhone_12_Dark.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="home__features">
      <div class="home__features-inner">
        <!-- <h3 class="home__features-heading">
          What will <span>you</span> use it for?
        </h3> -->
        <div class="home__features-grid">
          <div class="home__feature">
            <h4 class="home__feature-heading">Keep things safe</h4>
            <div class="home__feature-description">
              PINS, passwords, phone numbers. Keep them all safe from prying
              eyes.
            </div>
            <div class="home__feature-before-and-after">
              <div class="home__feature-before" v-if="!flipFeature1">
                <!-- <div class="home__feature-before-caption">Text</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939748/Shtum/Features/Feature_1_B.png"
                  alt=""
                />
              </div>
              <div class="home__feature-after" v-else>
                <!-- <div class="home__feature-after-caption">Emoji</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939747/Shtum/Features/Feature_1_A.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="home__feature">
            <h4 class="home__feature-heading">Keep your diary private</h4>
            <div class="home__feature-description">
              Are there people around who might be tempted to take a peek at
              your diary entries? They won't be able to make sense of these
              emojis.
            </div>
            <div class="home__feature-before-and-after">
              <div class="home__feature-before" v-if="!flipFeature4">
                <!-- <div class="home__feature-after-caption">Hide</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939748/Shtum/Features/Feature_4_B.png"
                  alt=""
                />
              </div>
              <div class="home__feature-after" v-else>
                <!-- <div class="home__feature-before-caption">Write</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939748/Shtum/Features/Feature_4_A.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="home__feature">
            <h4 class="home__feature-heading">Hide messages in open view</h4>
            <div class="home__feature-description">
              Communicate covertly in a group chat. Only those in the know will
              be able to read your hidden messages.
            </div>
            <div class="home__feature-before-and-after">
              <div class="home__feature-before" v-if="!flipFeature2">
                <!-- <div class="home__feature-before-caption">Hidden</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939749/Shtum/Features/Feature_2_A.png"
                  alt=""
                />
              </div>
              <div class="home__feature-after" v-else>
                <!-- <div class="home__feature-after-caption">Revealed</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939748/Shtum/Features/Feature_2_B.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="home__feature">
            <h4 class="home__feature-heading">Hide spoilers</h4>
            <div class="home__feature-description">
              Want to share your reaction to the latest movie or TV show without
              spoiling it for your mates who haven't seen it yet?
            </div>
            <div class="home__feature-before-and-after">
              <div class="home__feature-before" v-if="!flipFeature3">
                <!-- <div class="home__feature-before-caption">Hidden</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939749/Shtum/Features/Feature_3_A.png"
                  alt=""
                />
              </div>
              <div class="home__feature-after" v-else>
                <!-- <div class="home__feature-after-caption">Revealed</div> -->
                <img
                  class="home__feature-image"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621939748/Shtum/Features/Feature_3_B.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="home__features-secret-key">
          <div class="home__features-secret-key-grid">
            <div class="home__features-secret-key-text">
              <h4 class="home__features-secret-key-heading">
                Secure your secrets with a Shtum Key
              </h4>
              <div class="home__features-secret-key-small-print">
                Requires an in-app purchase
              </div>
            </div>
            <div class="home__features-secret-key-input"></div>
            <div class="home__features-secret-key-output"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      flipFeature1: false,
      flipFeature2: false,
      flipFeature3: false,
      flipFeature4: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.animateHeroImage);
  },
  unmounted() {
    document.removeEventListener("scroll", this.animateHeroImage);
  },
  methods: {
    comingSoon() {
      alert("Coming soon");
    },
    animateHeroImage() {
      this.endXPosition = 500;
      this.endYPosition = -500;
      return (this.$refs.heroImage.style.transform =
        "rotate(" +
        (15 + this.scrollPercentage() / 4) +
        "deg) translateX(" +
        (this.endXPosition * this.scrollPercentage()) / 500 +
        "px) translateY(" +
        (this.endYPosition * this.scrollPercentage()) / 100 +
        "px)");
    },
    scrollPercentage() {
      let scrollPercent = 0;
      const scrollTop = (
        document.documentElement ||
        document.body.parentNode ||
        document.body
      ).scrollTop;
      // console.log("scrollTop: ", scrollTop);
      const documentHeight = document.body.scrollHeight;
      // console.log("documentHeight: ", documentHeight);
      const windowHeight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      // console.log("windowHeight: ", windowHeight);
      scrollPercent = (scrollTop / (documentHeight - windowHeight)) * 100;
      // console.log("scrollPercent: ", scrollPercent);
      return scrollPercent;
    },
  },
};
</script>

<style>
.home__hero {
  height: calc(100vh - 4rem);
  background: var(--brand-primary-50);
  overflow: hidden;
  /* border: 1px solid red; */
}
.home__hero-inner {
  max-width: 64rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  overflow-x: initial;
  /* border: 1px solid red; */
}
.home__hero-left {
  padding: 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.home__hero-heading {
  margin: 1.5rem 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.125;
  text-align: center;
  color: var(--base-text-color);
}
.home__hero-app-store-links {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__hero-app-store-link:first-child {
  margin-right: 0.5rem;
}
.home__hero-app-store-badge {
  width: auto;
  height: 2.75rem;
}
.home__hero-app-store-cta {
  font-size: 1.25rem;
  font-weight: 600;
  white-space: pre;
  color: var(--base-text-color);
}
.home__hero-image-wrapper {
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.home__hero-image-wrapper img {
  max-height: calc(100vh - 4rem);
  padding: 3rem 0;
  object-fit: contain;
  /* min-height: 32rem; */
  max-height: 52rem;
  transform: rotate(15deg) translateX(0rem);
}

@media screen and (min-width: 720px) {
  .home__hero-left {
    padding: 3rem 0 0;
  }
}

@media screen and (min-width: 768px) {
  .home__feature,
  .home__features-secret-key {
    border-radius: 0.5rem;
  }
}

@media screen and (min-width: 960px) {
  .home__hero-inner {
    height: 100%;
    flex-direction: row;
  }
  .home__hero-left {
    width: 40%;
    padding: 0;
  }
  .home__hero-heading {
    text-align: left;
  }
  .home__hero-image-wrapper {
    width: 60%;
  }
  .home__hero-image-wrapper img {
    max-height: auto;
    padding: 4rem 0;
  }
}

.home__features {
  background: var(--secondary-background);
}
.home__features-inner {
  max-width: 48rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home__features-heading {
  margin: 1.5rem 0;
  padding: 0 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.125;
  color: var(--base-text-color);
}
.home__features-heading span {
  text-decoration: underline;
}
.home__features-grid {
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 3rem 2rem; */
}
.home__feature {
  padding: 1.5rem 1rem;
  background: var(--base-background);
}
@media screen and (min-width: 960px) {
  .home__features-inner {
    max-width: 64rem;
  }
  .home__features-grid {
    padding: 3rem 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  .home__feature {
    padding: 1.75rem 1.5rem;
  }
}
.home__feature-heading {
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.home__feature-description {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.home__feature-before-and-after {
  margin-top: 2rem;
}

.home__feature-before-and-after--vertical-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.home__feature-before,
.home__feature-after {
  padding: 0.25rem 0.5rem;
  background: var(--secondary-background);
  /* background: #ffefc1; */
  border-radius: 0.5rem;
  object-fit: contain;
}
.home__feature-before-and-after--vertical-layout .home__feature-before,
.home__feature-before-and-after--vertical-layout .home__feature-after {
  padding: 0.75rem 1rem;
  /* background: var(--secondary-background); */
  background: #ffefc1;
  border-radius: 0.5rem;
}
.home__feature-before-caption,
.home__feature-after-caption {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  color: var(--grey-50);
}
.home__feature-image {
  width: 100%;
  height: auto;
  /* object-fit: contain; */
}
.home__features-secret-key {
  width: 100%;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--inverted-background);
  /* border-radius: 0.75rem; */
}
.home__features-secret-key-grid {
  /* display: grid;
  grid-template-columns: 1fr;
  gap: 1rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home__features-secret-key-text {
}
.home__features-secret-key-heading {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--inverted-text-color);
}
.home__features-secret-key-small-print {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: right;
  color: var(--grey-50);
}
.home__features-secret-key-input {
}
.home__features-secret-key-output {
}
</style>
