<template>
  <header class="app__header">
    <nav class="app__primary-nav" role="navigation">
      <router-link class="app__home-link" to="/">
        <img
          class="app__logo"
          src="https://res.cloudinary.com/dv5har4fh/image/upload/v1621356508/Shtum/Logo_Lockup_Colour.png"
          alt="Shtum logo"
        />
      </router-link>
      <ul class="app__menu">
        <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/">Home</router-link>
        </li>
        <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/help">Help</router-link>
        </li>
        <!-- <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/privacy"
            >Privacy</router-link
          >
        </li>
        <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/terms">Terms</router-link>
        </li> -->
      </ul>
    </nav>
  </header>
  <router-view />
  <footer class="footer">
    <div class="footer__inner">
      <div class="footer__copyright-notice">&copy; 2021 Clever Crow</div>
      <nav class="footer__nav">
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Download</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <a class="footer__nav-group-link" href="">iPhone</a>
            </li>
            <li class="footer__nav-group-list-item">
              <a class="footer__nav-group-link" href="">Android</a>
            </li>
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Social</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <a
                class="footer__nav-group-link"
                href="https://twitter.com/shtum_app"
                >Twitter</a
              >
            </li>
            <li class="footer__nav-group-list-item">
              <a
                class="footer__nav-group-link"
                href="https://instagram.com/shtum_app"
                >Instagram</a
              >
            </li>
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Legal</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/privacy"
                >Privacy</router-link
              >
            </li>
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/terms"
                >Terms</router-link
              >
            </li>
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Help</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/help/#faqs"
                >FAQs</router-link
              >
            </li>
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/help/#contact"
                >Contact</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </footer>
</template>

<style>
/* Import Poppins in regular 400, medium 500, semi-bold 600, bold 700 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --base-font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  --base-font-size: 100%;
  --base-font-weight: 400;
  --base-line-height: 1.5;
  --grey-90: #31323a;
  --grey-50: #8b8e9e;
  --grey-40: #b8bac1;
  --brand-primary-50: #ffc516;
  --brand-primary-40: #fcd664;
  --brand-secondary-60: #128dc6;
  --brand-secondary-50: #0baaf3;
  --brand-secondary-40: #69c4ee;
  --base-overlay-color: rgba(0, 0, 0, 0.5);
  --popover-background: var(--brand-primary-40);
  --popover-text-color: #202127;
  --danger-background: #f95959;
  --danger-text-color: #fff;
}

/* Light mode */
:root {
  --base-background: #fff;
  --secondary-background: #f1f2f3;
  --inverted-background: #202127;
  --base-icon-color: #202127;
  --base-text-color: #202127;
  --secondary-text-color: #74778a;
  --inverted-text-color: #f1f2f3;
  --base-border: 1px solid #dedfe3;
  --base-placeholder-color: #b8bac1;
  --success-background: #202127;
  --success-text-color: #fff;
}

body {
  margin: 0;
  background: var(--base-background);
  color: var(--base-text-color);
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent background from scrolling when a modal view is open */
body.--modal-is-open {
  overflow: hidden !important;
}

*::placeholder {
  color: var(--base-placeholder-color);
}

/* Remove tap highlight on iOS */
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app__header {
  height: 4rem;
  padding: 0 1rem;
  background-color: var(--base-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  position: sticky;
  top: 0;
}
.app__primary-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app__home-link {
  display: flex;
}
.app__logo {
  width: 123px;
  height: 40px;
}
.app__menu {
  height: 5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app__menu-item {
  margin-left: 1.5rem;
}
.app__menu-list-item {
  padding: 0.25rem 0;
  border-bottom: 0.25rem solid transparent;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--brand-secondary-50);
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__menu-list-item.router-link-exact-active {
  /* border-bottom-color: var(--brand-secondary-50); */
  color: var(--secondary-text-color);
}
/* Footer */
.footer {
  background: var(--inverted-background);
  font-size: 1.125rem;
  color: var(--inverted-text-color);
  /* border: 1px solid red; */
}
.footer__inner {
  max-width: 48rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
@media screen and (min-width: 960px) {
  .footer__inner {
    max-width: 64rem;
    padding: 4rem 0;
  }
}
.footer__copyright-notice {
  margin: 2rem 4rem 0 0;
  padding: 0.5rem 0;
  color: var(--secondary-text-color);
  display: flex;
}
.footer__nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.footer__nav-group {
  min-width: 8rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.footer__nav-group-header {
  padding: 0.5rem 0;
  font-weight: 700;
}
.footer__nav-group-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer__nav-group-list-item {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  color: var(--secondary-text-color);
}
.footer__nav-group-link {
  text-decoration: none;
  color: var(--secondary-text-color);
}
/* Fade transition */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.1s opacity ease-out;
}

/* Longer Fade transition */
.fade-long-enter,
.fade-long-leave-to {
  opacity: 0;
}
.fade-long-enter-active {
  transition: 2s opacity ease-in-out;
}
.fade-long-leave-active {
  transition: 0.2s opacity ease-in-out;
}

/* Slide Up animation */
.slide-up-enter-active {
  animation: slide-up-in 0.3s ease-out forwards;
}
.slide-up-leave-active {
  animation: slide-up-out 0.3s ease-out forwards;
}
@keyframes slide-up-in {
  from {
    transform: translateY(40rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-up-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
}
</style>
